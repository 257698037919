.spinner-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 10px 0 10px;

  gap: 10px;
}

.spinner-container.hidden {
  display: none;
}
