.file_upload-wrapper {
  display: flex;
  flex-direction: column;

  margin-bottom: 0px;
}

.file_upload-wrapper .file_upload-wrapper-text_small {
  font-size: 11px;
  color: rgba(49, 51, 63, 0.4) !important;
  vertical-align: middle;
}

.file_upload-wrapper .file_upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 5px 5px;
  margin-top: 20px;
  border-radius: 0.25rem;

  background-color: rgb(240, 242, 246);
  color: rgb(163, 168, 184);

  position: relative;
}

.file_upload-wrapper .file_upload-container .file-input {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
}

.file_upload-wrapper .file_upload-container .file_upload-container-info p {
  color: initial;
  font-weight: 400;
}

.file_upload-wrapper .file_upload-container .file_upload-container-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 10px;
  margin-right: auto;
  margin-left: 10px;
  margin-bottom: 15px;
}

.office-checkbox{
  margin-top: 12px;
}

/* .file_upload-wrapper .download-container {
  margin-top: 10px;
} */

@media (min-width: 520px) {
  .file_upload-wrapper .file_upload-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .file_upload-wrapper .file_upload-container .file_upload-container-info {
    width: auto;
    margin: 0;
  }
}