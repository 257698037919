.file-processing-wrapper {
  display: flex;
  flex-direction: column;
}

.file-processing-wrapper .file-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  color: rgb(163, 168, 184);

  padding: 15px;
}

.file-info-container .file-info-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.file-info-container .file-info-content .file-name {
  font-size: 16px;
  color: black;

  padding: 0 10px;
}

.file-info-container .close-btn {
  color: black;
  cursor: pointer;
}
