.error-wrapper {
  background-color: rgba(255, 83, 83, 0.15);

  border: 1px solid rgba(28, 131, 225, 0.1);
  border-radius: 5px;

  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;

  font-weight: 400;
  color: rgb(119, 33, 30);
}
