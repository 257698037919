.success-wrapper {
    background-color: rgb(0, 247, 53, 0.2);

    border: 1px solid rgba(28, 131, 225, 0.1);
    border-radius: 5px;

    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;

    font-weight: 400;
    color: rgba(0, 92, 20);
}