.search-result-wrapper {
  margin-top: 10px;
}

.search-result-wrapper .search-table thead {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent; 
}

.search-result-wrapper .search-table thead tr th {
    font-weight: 100;
    color: #003767;
}

.search-result-wrapper .search-table tbody tr .borderless {
    border: transparent;
}

.search-result-wrapper .search-table tbody tr .borderless-right {
    border-right: transparent;
}