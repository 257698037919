.custom-btn {
  white-space: nowrap;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;

  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  margin: 0px;
  line-height: 1.6;
  width: auto;
  user-select: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(49, 51, 63, 0.2);
  color: black;

  cursor: pointer;
  z-index: 2;
}

.custom-btn:hover {
  color: rgb(255, 35, 35);
  border-color: rgb(255, 35, 35);
}

.custom-btn.border-green {
  border: 3px solid #00bfb2;
}

.custom-btn.border-green:hover {
  color: white;
  background-color: #00bfb2;
}