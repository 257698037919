.add-button {
    background: #007000 !important;
    margin-right: 7px;
    margin-left: 7px;
    font-weight: bold;
}

.delete-button {
    background: #db4432 !important;
    margin-right: 7px;
    margin-left: 7px;
    font-weight: bold;
}