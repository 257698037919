.custom-select-wrapper {
  display: flex;
  flex-direction: column;

  margin-bottom: 15px;
}

.custom-select-wrapper .custom-select-title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;

  margin-bottom: 7px;
}

.custom-select-wrapper .custom-select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-select-wrapper .custom-select {
  background-color: rgb(240, 240, 240);
  padding: 10px;
}

.custom-select-wrapper .custom-select:hover {
  border-color: rgb(255, 35, 35);
}

.custom-select-wrapper .custom-select:disabled {
  opacity: 0.5;
}