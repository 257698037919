.report-wrapper {
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
}

.report-wrapper .report-header {
    margin-bottom: 20px;
}

.report-wrapper .report-header .text-alt {
    margin-top: 15px;
}

.report-wrapper .report-form {
  border: 1px solid rgba(49, 51, 63, 0.2);
  border-radius: 0.25rem;

  padding: 15px;


}
