.text_area-container .text-area {
  background-color: rgb(240, 240, 240);
}

.text_area-container .text-area:focus {
  border-color: rgb(255, 35, 35);
}

.text_area-container .text_area-title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;

  margin-bottom: 7px;
}

.text_area-container .text_area-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.text_area-container .text-area::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  color: rgb(173, 173, 173);
}
