.warning-wrapper {
  background-color: rgba(83, 178, 255, 0.15);

  border: 1px solid rgba(28, 131, 225, 0.1);
  border-radius: 5px;

  padding: 20px;
  margin-bottom: 20px;

  font-weight: 400;
  color: rgb(30, 103, 119);
}

.warning-wrapper p {
  margin: 0;
}
