.categorization-wrapper .category {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.categorization-wrapper .category .category-item {
  width: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.categorization-wrapper .category .category-item .category-item-text {
  color: #003767;
}
