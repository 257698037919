header {
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

header .header-main_container {
  width: 100%;

  display: flex;
  flex-direction: column;
}

header .header-main_container .schedule-upload {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.schedule-branch-select {

    width: 25%;

  }

header .header-main_container .schedule-upload .progress_bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0;
}

header
  .header-main_container
  .schedule-upload
  .progress_bar-container
  .progress_bar {
  width: 100%;
  height: 50px;

  background-color: rgb(148, 57, 57);
}

header
  .header-main_container
  .schedule-upload
  .progress_bar-container
  .progress_bar
  .progress {
  height: 100%;
  width: 50%;

  position: relative;
  overflow: auto;

  background-color: green;
}

header
  .header-main_container
  .schedule-upload
  .progress_bar-container
  .progress_bar
  .progress
  .response-text {
  maxWidth: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  text-align: right;
  white-space: normal;
  position: relative;
  padding: 10px;

  font-size: 12px;
  font-size: 600;
  line-height: 13px;
  color: white;
}

header
  .header-main_container
  .schedule-upload
  .progress_bar-container
  .progress_bar
  .progress
  .response-text
  .sub-text {
    font-size: 12px;
}

@media (min-width: 641px) {
  header .header-banners {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  header .header-main_container .schedule-upload {
    flex-direction: row;

    white-space: nowrap;
  }

  header
    .header-main_container
    .schedule-upload
    .progress_bar-container
    .progress_bar
    .progress
    .response-text {
    font-size: 18px;
    line-height: 15px;
  }

  header .header-main_container .schedule-upload .progress_bar-container {
    padding: 0 15px;
  }
}

@media (min-width: 1025px) {
  .header-main_container .schedule-upload .progress_bar-container {
    width: 50% !important;
  }
}

@media (min-width: 1900px) {
  .header-main_container .schedule-upload .progress_bar-container {
    width: 60% !important;
  }
}

.branch-form{

  padding-top: 10px;
}

.file_upload-wrapper {
    display: flex;
    flex-direction: column;
  
    margin-bottom: 30px;
  }
  
  .file_upload-wrapper .file_upload-wrapper-text_small {
    font-size: 14px;
    color: rgba(49, 51, 63, 0.4) !important;
    vertical-align: middle;
  }
  
  .file_upload-wrapper .file_upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    padding: 25px 15px;
    margin-top: 12px;
    border-radius: 0.25rem;
  
    background-color: rgb(240, 242, 246);
    color: rgb(163, 168, 184);
  
    position: relative;
  }
  
  .file_upload-wrapper .file_upload-container .file-input {
    position: absolute;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 100%;
  
    opacity: 0;
  }
  
  .file_upload-wrapper .file_upload-container .file_upload-container-info p {
    color: initial;
    font-weight: 500;
  }
  
  .file_upload-wrapper .file_upload-container .file_upload-container-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
    gap: 10px;
    margin-right: auto;
    margin-left: 10px;
    margin-bottom: 15px;
  }
  
  .office-checkbox{
    margin-top: 12px;
  }
  
  /* .file_upload-wrapper .download-container {
    margin-top: 10px;
  } */
  
  @media (min-width: 520px) {
    .file_upload-wrapper .file_upload-container {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .file_upload-wrapper .file_upload-container .file_upload-container-info {
      width: auto;
      margin: 0;
    }
  }
  
